export default class Debounce {
    public static init<FType extends (...args: any[]) => any>(
        processFunc: FType,
        duration = 1500,
        dirtyFunc?: FType,
        doneFunc?: FType,
    ) {
        function debounce(
            processFunc: FType,
            duration: number,
            dirtyFunc?: FType,
            doneFunc?: FType,
        ) {
            let timer: ReturnType<typeof setTimeout>;
            let currentlyBusy = false;
            let liveModificationNumber = 0;
            function debounceRunner() {
                clearTimeout(timer);
                if (dirtyFunc) {
                    liveModificationNumber++;
                    dirtyFunc(liveModificationNumber);
                }

                if (currentlyBusy) {
                    return;
                }
                timer = setTimeout(async function () {
                    if (currentlyBusy) {
                        return;
                    }
                    currentlyBusy = true;
                    let modificationNumberProcessed = liveModificationNumber;
                    await processFunc();
                    currentlyBusy = false;
                    if (modificationNumberProcessed < liveModificationNumber) {
                        debounceRunner();
                    }
                    if (doneFunc) {
                        doneFunc(modificationNumberProcessed, liveModificationNumber);
                    }
                }, duration);
            };
            return debounceRunner;
        }
        return debounce(processFunc, duration, dirtyFunc, doneFunc);
    }
}
