import SearchComponent, { SearchOptions } from './SearchComponent';
import SearchResults, { Result } from './SearchResults';
import Axios from "axios";
import React from 'react';

type HomeProps = {}

type HomeState = {
    query: string;
    tld: string;
    options: SearchOptions;
    results: Array<Result>;
    page: number;
}


export default class Home extends React.Component<HomeProps, HomeState> {
    private MAX_RECORDS = 50;
    private MAX_PAGES = 10;

    constructor(props: HomeProps) {
        super(props);
        this.state = {
            query: '',
            tld: '',
            options: {
                allowDashes: 0,
                allowNumbers: 0,
                allowPlurals: 0,
                allowMultiWords: 0,
                maxLength: 15
            },
            results: [],
            page: 1
        };
        this.handleChangeQuery = this.handleChangeQuery.bind(this);
        this.handleScrollThroughCompleted = this.handleScrollThroughCompleted.bind(this);
        this.searchDomains = this.searchDomains.bind(this);
    }

    public async componentDidMount() {
        window.addEventListener('keydown', (event) => {
            if (event.keyCode === 13) {
                event.preventDefault();
                return false;
            }
        });
        // initial search results
        await this.searchDomains(true);
    }

    private handleChangeQuery(query: string, tld: string, options: SearchOptions) {
        this.setState({
            query: query,
            tld: tld,
            options: options,
            results: [],
            page: 1
        }, async () => { await this.searchDomains() });
    }

    private handleScrollThroughCompleted() {
        if (this.state.page >= this.MAX_PAGES) {
            return;
        }
        this.setState({
            page: this.state.page + 1,
        }, async () => { await this.searchDomains() });
    }

    private async searchDomains(initialLoad = false) {
        let searchResult;
        const cleanQuery = this.state.query.replace(/[^-0-9a-zA-Z]/i, '');
        searchResult = await Axios.get(
            process.env.REACT_APP_CORS!, {
            params: {
                query: cleanQuery,
                tld: this.state.tld,
                allowDashes: this.state.options.allowDashes,
                allowNumbers: this.state.options.allowNumbers,
                allowPlurals: this.state.options.allowPlurals,
                allowMultiWords: this.state.options.allowMultiWords,
                maxLength: this.state.options.maxLength,
                page: this.state.page
            }
        }
        );
        if (searchResult.status === 200 && searchResult.data) {
            this.setState({
                results: initialLoad ? searchResult.data : this.state.results.concat(searchResult.data)
            }, () => {
                console.log('Loading search page: ' + this.state.page);
            });
        }
        else {
            this.setState({
                results: []
            });
        }
    }

    public render() {
        return (
            <>
                <SearchComponent onUpdateSearch={this.handleChangeQuery} />
                <SearchResults
                    results={this.state.results}
                    onScrollThroughCompleted={this.handleScrollThroughCompleted}
                    preferredTld={this.state.tld}
                />
            </>
        );
    }

}
