import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export type Result = {
    id: number,
    word: string,
    definition: string,
    isPlural: number,
    appAvailable: number,
    proAvailable: number,
    bizAvailable: number,
    xyzAvailable: number,
    techAvailable: number,
    orgAvailable: number,
    netAvailable: number,
    devAvailable: number,
    comAvailable: number
}

type SearchResultsProps = {
    results: Array<Result>,
    onScrollThroughCompleted: () => void,
    preferredTld: string
}

type ModalState = {
    word: string,
    definition: string,
    tld: string,
    otherTlds: Array<string>
}

type SearchResultsState = {
    showModal: boolean,
    modalState: ModalState
}

export default class SearchResults extends React.Component<SearchResultsProps, SearchResultsState> {
    constructor(props: SearchResultsProps) {
        super(props);
        this.state = {
            showModal: false,
            modalState: {
                word: '',
                definition: '',
                tld: '',
                otherTlds: []
            }
        };
        this.componentDidMount = this.componentDidMount.bind(this);
        this.checkForFullScroll = this.checkForFullScroll.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    public componentDidMount() {
        window.addEventListener('scroll', this.checkForFullScroll);
    }

    public componentDidUpdate() {
        window.addEventListener('scroll', this.checkForFullScroll);
    }

    public showModal(modalState: ModalState) {
        this.setState({
            showModal: true,
            modalState: modalState
        })
    }

    public hideModal() {
        this.setState({
            showModal: false
        })
    }

    public goToAffiliate(provider: string, domain: string) {
        window.open('/out.php?aff=' + provider + '&domain=' + domain);
    }

    private checkForFullScroll() {
        if ((window.innerHeight + window.pageYOffset + 2) >= document.body.offsetHeight) {
            window.removeEventListener('scroll', this.checkForFullScroll);
            this.props.onScrollThroughCompleted();
        }
    }

    private getAvailableTlds(result: Result) {
        const preferredTld = this.props.preferredTld;
        const fullTldList = new Array<string>();

        if (result.appAvailable) {
            fullTldList.push('app');
        }
        if (result.proAvailable) {
            fullTldList.push('pro');
        }
        if (result.bizAvailable) {
            fullTldList.push('biz');
        }
        if (result.xyzAvailable) {
            fullTldList.push('xyz');
        }
        if (result.techAvailable) {
            fullTldList.push('tech');
        }
        if (result.orgAvailable) {
            fullTldList.push('org');
        }
        if (result.netAvailable) {
            fullTldList.push('net');
        }
        if (result.devAvailable) {
            fullTldList.push('dev');
        }
        if (result.comAvailable) {
            fullTldList.push('com');
        }

        const displayTld = preferredTld ? preferredTld : fullTldList[Math.floor(Math.random() * fullTldList.length)];
        return {
            displayTld: displayTld,
            otherTlds: fullTldList // otherTlds: fullTldList.filter(tld => tld !== displayTld)
        }
    }

    public render() {
        const workingDomain = this.state.modalState.word + '.' + this.state.modalState.tld;
        return <>                
            <div id="search-results">
                {this.props.results.map((result) => {
                    const tldInfo = this.getAvailableTlds(result);

                    let shortenedDefinition: null | string = null;
                    if (result.definition.length > 50) {
                        shortenedDefinition = result.definition.substring(0, 50);
                    }
                    return <div
                        className="result"
                        key={result.id}
                        onClick={(e) => {
                            this.showModal({
                                word: result.word,
                                tld: tldInfo.displayTld,
                                definition: result.definition,
                                otherTlds: tldInfo.otherTlds
                            });
                        }}
                    >
                        <img src="images/arrow.png" alt="arrow" />
                        <strong>{result.word + '.' + tldInfo.displayTld}</strong>
                        <p className="definition">{shortenedDefinition ? shortenedDefinition + '...' : result.definition}</p>
                        <p className="available">AVAILABLE!</p>
                    </div>;
                })}
            </div>

            {this.props.results.length === 0 && <div id="no-results">No results found, please try another search term.</div>}

            <Modal
                size="lg"
                aria-labelledby="domain-modal"
                centered
                show={this.state.showModal}
                id="registration-modal"
            >
                <Modal.Header id="registration-modal-header" closeButton onClick={this.hideModal}>
                    <Modal.Title id="domain-modal">
                        {workingDomain}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body id="registration-modal-body" >
                    <>
                        <p id="registration-modal-available">AVAILABLE!</p>
                        <p id="registration-modal-definition"><span>definition: </span>{this.state.modalState.definition}</p>
                        <p>
                            Choose a registrar below to register <strong>{workingDomain}</strong> before someone else does!
                        </p>
                        <ul id="registration-modal-registrars">
                            <li><button onClick={(e) => this.goToAffiliate('namecheap', workingDomain)}>NameCheap.com</button></li>
                            <li><button onClick={(e) => this.goToAffiliate('123', workingDomain)}>123-reg.co.uk</button></li>
                            <li><button onClick={(e) => this.goToAffiliate('godaddy', workingDomain)}>GoDaddy.com</button></li>
                        </ul>
                        <p><small>Note: I may earn a small affiliate commission if you use one of the above registrars.</small></p>
                        {(() => {
                            let otherTldsAvailable = <></>;
                            const filteredTlds = this.state.modalState.otherTlds.filter(tld => tld !== this.state.modalState.tld);
                            if (filteredTlds.length > 0) {
                                otherTldsAvailable = <div>
                                    <strong>Also available:</strong>
                                    <ul className="alternate-tlds">
                                        {
                                            filteredTlds
                                                .map((tld) => {
                                                    return <li key={tld}>
                                                        <a href="/#" onClick={(e) => {
                                                            this.showModal({
                                                                word: this.state.modalState.word,
                                                                tld: tld,
                                                                definition: this.state.modalState.definition,
                                                                otherTlds: this.state.modalState.otherTlds
                                                            });
                                                        }}>
                                                            {this.state.modalState.word + '.' + tld}
                                                        </a>
                                                    </li>;
                                                })
                                        }
                                    </ul>
                                </div>;
                            }
                            return otherTldsAvailable;
                        })()}
                    </>
                </Modal.Body>
                <Modal.Footer id="registration-modal-footer">
                    <Button onClick={this.hideModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>;
    }
}